import { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api_url, logo, title } from '../../config/Config';
import _fetch from '../../config/api';
import toasted from '../../config/toast';

const AdminSideLogin = (props: any) => {

    const [btnDisable, setbtnDisable] = useState(false);
    const { address } = useParams();

    useEffect(() => {
        document.title = `${props.pageTitle}`;
        if (address && address !== '') {
            submitLogin();
        } else {
            handleInvalidAddress();
        }
    }, [address]);

    const navigate = useNavigate();

    const submitLogin = async () => {
        setbtnDisable(true)
        let data = {
            "wallet_address": address,
        }

        let res = await _fetch(`${api_url}auth/login`, "POST", data, {})

        if (res?.status === 'success') {

            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", res?.token);
            localStorage.setItem("user", JSON.stringify(res?.data?.user));
            // toasted.success(res?.message);
            navigate("/dashboard");
        }
        else {
            localStorage.clear();
            toasted.error(res?.message);
            console.log(res?.message);
            setbtnDisable(false);
        }
    }

    const handleInvalidAddress = () => {
        localStorage.clear();
        toasted.error('Invalid or missing address. Please check the URL.');
        navigate("/login");
    };

    return null;
}

export default AdminSideLogin;
