import React, { useEffect, useState } from 'react'
import { api_url, coinSymbol, sellContractAddress, approvalAddress } from '../../config/Config';
import toasted from '../../config/toast';
import _fetch from '../../config/api';
import LoaderDyn from '../../Layout/LoaderDyn';

const BuyBid = (props) => {
    const [amount, setAmount] = useState("");
    const [approveLoader, setapproveLoader] = useState('0');
    const [btnDisable, setbtnDisable] = useState(false);
    const [activeLogin, setactiveLogin] = useState(false);
    const [checkHit, setcheckHit] = useState(0);
    const [sponserAddress, SetsponserAddress] = useState();

    const getSponserInfo = async () => {
        let res = await _fetch(`${api_url}user/sponserInfo/`, "GET", {})
        if (res?.status === 'success') {
        SetsponserAddress(res?.data?.wallet_address);
        // console.log('res?.data?.data sponser',res?.data.wallet_address);
        }
    }


    const onBuyVista = async (hash) => {

        let postData = {
            amount: amount, //usdt amount
            hash: hash,
            wallet_address: props?.maskData?.address,
        }
        let response = await _fetch(`${api_url}buy`, 'POST', postData);
        if (response?.status === 'success') {
            toasted.success(response?.message)
            window.location.reload();
            setapproveLoader('3')
            setbtnDisable(false);


        } else {
            toasted.error(response?.message);
            setapproveLoader('3');
            setbtnDisable(false);


        }

    }

    const StakeAmount = async (hash) => {
        console.log('hash', hash);
    
    
    let data = {
      "username": props.userName,
      "amount": parseInt(amount),
      "investCode": props.package,
      "hash": hash,
      'eth_address': props?.user_wallet,
    }
    console.log('data', data);

    let res = await _fetch(`${api_url}investment/invest-now`, "POST", data)
    console.log('res', res);
    if (res?.status === 'success') {
      toasted.success(res?.message);
      setbtnDisable(false)
      props.onCloseModal();
      setapproveLoader('3')
      window.location.reload();
      // Navigate("/dashboard");
    }
    else {
      toasted.error(res?.message);
      setbtnDisable(false)

      setapproveLoader('3')

    }
    }

    const approveAndSendTokens = async () => {
        // if (checkHit == 0) {
        if (amount >= 50) {
            setbtnDisable(true)

            const web3 = props?.maskData?.web3;
            const account = props?.maskData?.address;
            const tokenContract = props?.maskData?.tokenContract;
            const StakeContract = props?.maskData?.StakeContract;
            const ReStakeContract = props?.maskData?.ReStakeContract;
            const sellContract = props?.maskData?.sellContract;
            // console.log(web3, account);
            // console.log(' tokenContract', tokenContract);

            if (web3 && account && tokenContract) {
                setapproveLoader('1');

                const newAmount = (Math.abs(amount));
                console.log('chkAdd');

                try {

                    const finalAmount = web3.utils.toWei(`${amount}`, 'ether'); // Replace with the desired approval amount
                    const _gasPrice = await web3.eth.getGasPrice();
                    const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));

                    const gasPriceGwei = gasPrice;
                    const gasPriceWei = Math.floor(gasPriceGwei * 1.3);
                    console.log('chkAddresssdsds');


                    const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

                    const getEstimatedGas2 = await tokenContract.methods.approve(sellContractAddress, finalAmount).estimateGas({
                        from: account
                    })

                    const approvalClear2 = await tokenContract.methods.approve(sellContractAddress, finalAmount).send({
                        from: account,
                        gasPrice: gasPriceWei.toString(),
                        gasLimit: getEstimatedGas2
                    }).once('transactionHash', function (hash) {

                    }).on('error', function (error) {
                        return false;
                    }).then(async function (receipt) {
                        setapproveLoader('2')
                        console.log("hello")

                        const getEstimatedGas = await sellContract.methods.sellStake(finalAmount, sponserAddress).estimateGas({ from: account });
                        const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));

                        // console.log('gasPrice', gasPrice, estimatedGas, finalAmount);
                        const _gasPriceGwei = gasPrice;
                        const _gasPriceWei = Math.floor(_gasPriceGwei * 1.3);

                        await sellContract.methods.sellStake(finalAmount.toString(), sponserAddress).send({
                            from: account,
                            gasPrice: _gasPriceWei.toString(),
                            gasLimit: estimatedGas
                        }).once('transactionHash', function (hash) {
                            // console.log('transactionHash hash: ', hash);

                            // console.log(hash);
                            // setGethash(hash)
                            // setchangeSection(true)
                        }).on('error', function (error) {
                            // console.log('\n[ERROR]\n\n' + error
                            //     .message);
                            let msg = error.message.split(":");
                            // toastr.error("Error " + error.code +
                            //     " : " + msg[1]);
                        }).then(async function (receipt) {
                            // call node apiLOG
                            console.log('receipt2222', receipt);
                            console.log('receipt.transactionHash', receipt.transactionHash);

                            StakeAmount(receipt.transactionHash)
                            setactiveLogin(true)
                        })
                    });

                } catch (error) {
                    // console.error('Error approving and sending tokens:', error);
                    toasted.error(error?.data?.message);
                    setbtnDisable(false)
                    setapproveLoader('3')
                }
            } else {
                setbtnDisable(false)
            }
        } else {
            toasted.error(`Please enter an amount between 50 and 250 USDT`);
        }
    };

    useEffect(() => {
        getSponserInfo();
        getfundData()
    }, []);

    const getfundData = async () => {
        let data = await _fetch(`${api_url}bidding/last-sell`, "GET", {})

        if (data?.status === "success") {
            setcheckHit(data?.data);
        }
    }
    return (
        <div>
            <div className='tabs-data'>
                <form>

                    <div className='bidding_sec'>
                        <span>
                            USDT
                        </span>
                        <input type='text' className='ent-amt' placeholder='Enter Amount' onChange={(e) => { e.target.value >= 0 ? setAmount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} />

                    </div>

                    <div className='bidding_sec'>
                        <span>
                            Price
                        </span>
                        <input type='number' placeholder={props.coinPrice + " " + 'USDT/VISTA'} readOnly />
                    </div>

                    <div className='bidding_sec'>
                        <span>
                            Vista
                        </span>
                        <input type='number' placeholder='Enter Amount' value={(Math.abs(amount / props.coinPrice))?.toFixed(4)} readOnly />

                        {/* <input type='text' placeholder='Vista' onChange={(e) => { e.target.value >= 0 ? setAmount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} /> */}
                    </div>

                    <div className='buttonsj-group'>
                        <button type='button' className='btn buy-btn' onClick={approveAndSendTokens} disabled={btnDisable}>
                            {btnDisable === true &&
                                <LoaderDyn text={'Wating for Confirmation'} />

                            }
                            {btnDisable === false && 'BUY'}
                        </button>

                        {/* <button type='button' className='btn buy-btn' >
                           BUY
                        </button> */}

                    </div>
                </form>
            </div>
        </div>
    )
}

export default BuyBid