import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { api_url, website } from '../config/Config';
import _fetch from '../config/api';
import toasted from '../config/toast';

const Header = () => {

  const [open, setOpen] = useState(false);
  const [sponsorId, setSponsorId] = useState('');

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  let location = useLocation();
  const navigate = useNavigate();
  const auth = localStorage.getItem('auth');

  const apiCalled = useRef(false);


  useEffect(() => {
    if (!apiCalled.current) {
      apiCalled.current = true;
    // if (auth === "true") {
    // if (location.pathname !== '/dashboard') {
      return () => {
        (async () => {
          const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
          if(data1?.message == "jwt expired" || data1?.message == 'invalid signature'){
            localStorage.clear();
            navigate("/login");
          }
          if (data1.status === 'success') {
            if (data1?.data?.data) {
              setSponsorId(data1?.data?.data?.username);
            }
          }
        })();
      // }
    }
  }
    // }
  }, [location.pathname]);

  const LogOut = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }



  const ToggleButton = () => {
    const absx = document.body;
    absx.classList.toggle("show-index-bar");
  }

  return (
    <>
      <div className="app-header">
        <div className='for_toggle_scan'>
          <div className="app-sidebar__toggle" data-toggle="sidebar">
            <a className="open-toggle" href="#">
              <svg className="header-icon mt-1" onClick={ToggleButton} xmlns="http://www.w3.org/2000/svg" height="24"
                viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z"></path>
              </svg>
            </a>

          </div>

          {/* <div className="header-left">
            <div className="link-copy ">
              <input type='text' value={`${website}register/${sponsorId}`} disabled></input>
              <div className="icon-copy">
                <FileCopyIcon onClick={() => { copyToClipboard(`${website}register/${sponsorId}`) }} />
              </div>
            </div>
            <div className="icons_im">
              <img src={scan_code} className="scan_code" alt="scan" onClick={onOpenModal} />


              <Modal open={open} onClose={onCloseModal} center
                classNames={{
                  modal: "qr_modal",
                }}>
                <QRCode className="scan_modal" value={`${website}register/${sponsorId}`} />
              </Modal>
            </div>
          </div> */}
        </div>
        <div className="right-header-sec">
          {/* <div className="icons_im">
            <input type='text' value="https://web.gtccapital.com/auth/signup/" disabled></input>

            <img src={scan_code} className="scan_code" alt="scan" />

            <FileCopyIcon />
          </div> */}


          <button className='button_style' onClick={() => LogOut()}>Log Out</button>
        </div>
      </div>




    </>
  )
}

export default Header