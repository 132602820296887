import React from 'react';
import { FadeLoader } from 'react-spinners'

const LoaderDyn = (props:any) => {
    return (
        <>
            <div className="color-ring">
                <FadeLoader className='loader-section' color="#fff" />

               {props.text}
            </div>

        </>
    );
}

export default LoaderDyn;
