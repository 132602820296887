import React, { useEffect, useState } from "react";
import { api_url, image_url, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateformat from "dateformat";
import { ColorRing } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const ArchieverHistory = (props: any) => {

  useEffect(() => {
    document.title = `${props.pageTitle}`;
  });

  const [archieverHistory, setArchieverHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);

  useEffect(() => {
    getArchieverHistory();
  }, [page, limit]);

  const getArchieverHistory = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}sponsor/archiever-history?limit=${limit}&page=${page}&sort=created_at`, "GET", {})
    if (res?.status === "success") {
      setLoading(false);

      setArchieverHistory(res?.data?.archieverHistory);
      setTotalRecords(res?.total);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  return (
    <main>
      <div className="uni-level-outer">
        <div className="coustom_container">
          <div className="level_inner loader_main">
            {loading &&
              <div className="spinner_bg">

                <div className="color_ring">  <ColorRing
                  visible={true}
                  height="80"
                  width="80"

                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                /></div>

              </div>
            }
            <div className="top_section_unilevel cash_flow">
              <h4>ARCHIEVER HISTORY</h4>
            </div>
            <div className="table_section">
              <div className="scrolling_table">
                <div className="scroll_under">
                  <Table className="support_table">
                    <thead>
                      <tr className="table_heading_row">
                        <th className="table_heading">#</th>
                        <th className="table_heading">Name</th>
                        <th className="table_heading">Phone Number</th>
                        <th className="table_heading">Address</th>
                        <th className="table_heading">Qualification</th>
                        {/* <th className="table_heading">Addhar Fornt Image </th>
                        <th className="table_heading">Addhar Back Image </th> */}
                        <th className="table_heading">Created Date </th>
                        {/* <th className="table_heading">Action </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {archieverHistory?.map((item: any, index: any) => {
                        return (
                          <>
                            <tr className="table_data_row">
                              <td className="table_data">{page > 1 ? index + keyCount + 1 : index + 1}</td>
                              <td className="table_data">
                                <div className="pic_text">{item?.name}</div>
                              </td>
                              <td className="table_data">{item?.phoneNumber}</td>
                              <td className="table_data payment_fee">{item?.address}</td>
                              <td className="table_data"> {item?.qualification} </td>
                              {/* <td className="table_data">
                                <img src={`data:image/jpeg;base64,${image_url}/${item?.addharfrontimage}`} />
                              </td>
                              <td className="table_data">
                                <img src={`data:image/jpeg;base64,${image_url}/${item?.addharbackimage}`} />
                              </td> */}
                              <td className="table_data"> {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                              {/* <td className="table_data">
                                <Link to={`/edit-archiever/${item.phoneNumber}`}>Update</Link>
                              </td> */}
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </Table>
                  {/* {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecords / 10}
                    previousLabel="<"
                  />} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ArchieverHistory;