import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import profession1 from '../../Assets/Image/profession1.png';
import premium from '../../Assets/Image/premium.png';
import premium_1 from '../../Assets/Image/premium_1.png';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";

const BuyInfra = (props: any) => {


    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });
    const [open, setOpen] = useState(false);

    // const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [professoinalPackage, setProfessoinalPackage] = useState({ title: "", package_range: "", _id: "" });
    const [advancedPackage, setAdvancedPackage] = useState({ title: "", package_range: "", _id: "" });
    const [premiumPackage, setPremiumPackage] = useState({ title: "", package_range: "", _id: "" });
    const [valueChange, setValueChange] = useState("");
    const [method, setMethod] = useState('');
    const [userName, setUserName] = useState('');
    const [avaliableFund, setAvaliableFund] = useState({ AvaliableDeposit: "-" });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPackage();
        getAvaliableFund();
    }, []);

    const Navigate = useNavigate();

    const getPackage = async () => {
        let res = await _fetch(`${api_url}investment/get-package`, "GET", {})
        if (res?.status === 'success') {
            setProfessoinalPackage(res?.Package?.[2]);
            setAdvancedPackage(res?.Package?.[0]);
            setPremiumPackage(res?.Package?.[1]);
        }
    }

    const getAvaliableFund = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}profile/avaliablefund`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setAvaliableFund(res?.data);
        }
    }

    const BuyNow = async (type: any) => {

        let data = {
            "username": userName,
            "amount": parseInt(valueChange),
            "investCode": type === 'Starter' ? advancedPackage?._id : " ",
        }
        
        let res = await _fetch(`${api_url}investment/invest-now`, "POST", data)
        if (res?.status === 'success') {
            toasted.success(res?.message);
            // Navigate("/dashboard");
        }
        else {
            toasted.error(res?.message);
        }
    }

    return (
        <>
            <main>
                <div className="buy_section loader_main">
                    {loading &&
                        <div className="spinner_bg">

                            <div className="color_ring">  <ColorRing
                                visible={true}
                                height="80"
                                width="80"

                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                            /></div>

                        </div>
                    }

                    <div className="coustom_container">
                        <div className="buy_top_sec">
                            <h4>
                                BUY INFRA
                            </h4><p><span>Avaliable balance:</span> ${Number(avaliableFund?.AvaliableDeposit)?.toFixed(4)}</p>
                            {/* <div className="top_btn">
                                <button className="button_style">Myself</button>
                                <button className="button_style">Downline</button>
                            </div> */}
                        </div>

                        <div className="row cards_sec">
                            {/* <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card mb-4">

                                    <div className="card-body">
                                        <img src={christmas_tree} className="christmas" alt="img" />
                                        <h3>Christmas Infra</h3>
                                        <div className="card_bottom_Sec">
                                            <div className="inc_section">
                                                <h6>INC</h6>
                                                <h6>+5%</h6>
                                            </div>
                                            <div className="inc_section">
                                                <h6>MAX EARN</h6>
                                                <h6>700%</h6>
                                            </div>
                                            <div className="buy-section">
                                                <p className="select_amount">

                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select Amount</option>
                                                        <option value="1">$10,000</option>
                                                        <option value="2">$15,000</option>
                                                        <option value="3">$20,000</option>
                                                    </select>
                                                </p>
                                                <button className="button_style mt-3 w-100" onClick={onOpenModal}>BUY NOW</button>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div> */}



                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card mb-4">

                                    <div className="card-body">
                                        <img src={premium} className="christmas" alt="img" />
                                        <h3>{advancedPackage?.title}</h3>
                                        <div className="card_bottom_Sec">
                                            {/* <div className="inc_section">
                                                <h6>INC</h6>
                                                <h6>+4%</h6>
                                            </div> */}
                                            <div className="inc_section">
                                                <h6>MAX EARN</h6>
                                                <h6>350%</h6>
                                            </div>
                                            <div className="buy-section">
                                                <p className="select_amount">

                                                    {/* <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select Amount</option>
                                                        <option value="1">$10,000</option>
                                                        <option value="2">$25,000</option>
                                                        <option value="3">$30,000</option>
                                                    </select> */}

                                                    <input type="number" placeholder={advancedPackage?.package_range ? `$${advancedPackage?.package_range.replace(",", "-$")}` : "-"} onChange={(e: any) => setValueChange(e.target.value)} />
                                                </p>

                                                <p className="select_amount">
                                                    <input type="text" placeholder="enter username" onChange={(e: any) => setUserName(e.target.value)} />
                                                </p>
                                                {/* <p className="select_amount">
                                                    <select className="form-select" aria-label="Default select example" onChange={(e: any) => setMethod(e.target.value)}>
                                                        <option selected>Select Payment Method</option>
                                                        <option value="1">Buy From Deposit Fund</option>
                                                        <option value="2">Buy From Wallet Fund</option>
                                                    </select>
                                                </p> */}

                                                <button className="button_style w-100 mt-3" onClick={() => BuyNow("Starter")}>BUY NOW</button>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <Modal open={open} onClose={onCloseModal} center classNames={{
                modal: "buy_modal",

            }}>
                <p className="modal_data mb-3">
                    <h6>Price</h6>
                    <h6>$6,000</h6>
                </p>
                <p className="modal_data mb-3">
                    <h6>Available</h6>
                    <h6>$68,000</h6>
                </p>
                <p className="modal_data mb-3">
                    <h6>Rebuy</h6>
                    <h6>$22,000</h6>
                </p>
                <p className="need">Need 3,888.50 in wallet to make a purchase.</p>
                <div className="check_input">
                    <input type="checkbox" placeholder="" /><label>Use Rebuy Amount</label>

                </div>
                <p className="mt-3 mb-4">(20% of Infra Price can be used from Rebuy)</p>
                <a href="/deposit" className="button_style load_btn d-flex justify-content-center">Load eUSD</a>


            </Modal>
        </>

    );
};

export default BuyInfra;
