import React from 'react';
import { FadeLoader } from 'react-spinners'

const Loader = () => {
    return (
        <>
            <div className="color-ring">
                <FadeLoader className='loader-section' color="#fff" />

                Please Wait...
            </div>

        </>
    );
}

export default Loader;
