import React from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';

const Demo = () => {
  return (
    <div>
        <Header/>
        <Sidebar/>
    </div>
  );
}

export default Demo;
