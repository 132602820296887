import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import drag_file from '../../Assets/Image/darg_file.png';
import ques from '../../Assets/Image/ques.png';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';

const Support = (props: any) => {

    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });

    const [selectedOption, setSelectedOption] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [uploadImage, setUploadImage] = useState('');

    const handleImageChange = (e: any) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result as string;;
                setUploadImage(base64Image);
            };

            reader.readAsDataURL(selectedImage);
        }
    };

    const SubmitTicket = async () => {
        // let formData = new FormData();
        let data = {
            "subject": selectedOption,
            "description": description,
            "image": uploadImage
        }

        let res = await _fetch(`${api_url}support/support-ticket`, "POST", data, {})
        if (res?.status === "success") {
            toasted.success(res?.message);
        }
    }

    return (
        <main>
            <div className="support_main">
                <div className="coustom_container">
                    <div className="support_inner">
                        <div className="top_head_sec">
                            <h4>CREATE TICKET</h4>

                        </div>
                        <div className="support-section">
                            <p className="select_reason">
                                <select className="form-select" aria-label="Default select example" onChange={(e: any) => setSelectedOption(e.target.value)} value={selectedOption} >
                                    <option selected>Question about node</option>
                                    {/* <option value="Please specify your reason">Please specify your reason</option> */}
                                    <option value="Question about income">Question about income</option>
                                    <option value="Others">Others</option>
                                </select>
                            </p>
                            <div className="desc">
                                <textarea rows={4} placeholder='Description' className='text_sec' onChange={(e: any) => setDescription(e.target.value)} value={description} />
                            </div>
                        </div>

                        <div className="row support_row">
                            <div className="col-lg-7 col-md-6 col-sm-6">
                                <div className="right_sec_support">
                                    <p>Attach upto 5 files<br />
                                        The file size should not exceed 4 MB<br />
                                        File format .png, jpeg, jpg</p>
                                    <h4>24x7 Live</h4>
                                    <img src={ques} alt="question" className='question' />
                                </div>

                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="upload_document d-none">

                                    <div className="main">

                                        <div className="upload-input">
                                            <p>Upload your files here</p>
                                            {/* <label htmlFor="upload-img"> */}
                                            <span> <img src={imagePath ? imagePath : uploadIcon} alt="card" /> </span>
                                            <span><input type="file" /></span>
                                            {/* </label> */}
                                            {/* <label className="button_style browse" htmlFor="upload-img"  >Browse Files</label> */}
                                        </div>
                                    </div>
                                </div>

                                {/* <label for="myfile">Select a file:</label> */}
                                <input type="file" id="myfile" name="myfile"/>
                            </div>
                        </div>
                        <div className="send_sec">
                            <button className='button_style send' onClick={SubmitTicket}>Send</button>
                        </div>


                    </div>
                </div>
            </div>
        </main>
    )
}

export default Support