import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Row, Col, Table } from 'react-bootstrap';
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from '../../config/api';
import dateformat from "dateformat";
import { useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import ReactPaginate from "react-paginate";
import { PersonRounded } from '@mui/icons-material';
import card_1 from "../../Assets/Image/salespics.svg";

const DirectIncome = (props: any) => {
    const { type } = useParams();

    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });

    const [directIncome, setDirectIncome] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [sort, setSort] = useState('-created_at');
    const [levelSearch, setLevelSearch] = useState('');
    const [searchedType, setSearchType] = useState('level');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [todayIncome, setTodayIncome] = useState({ todayAmount: 0, yesterdayAmount: 0 });
    const [keyCount, setKeycount] = useState(0);
    const [limit, setlimit] = useState(10);
    const [allLevels, setAllLevels] = useState([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
    ]);

    useEffect(() => {
        getIncomeReport();
    }, [levelSearch, page, type, limit]);

    const getIncomeReport = async () => {
        setLoading(true);
        if (levelSearch) {
            var searchData = `&level=${levelSearch}`;
        } else {
            var searchData = "";
        }

        let res = await _fetch(`${api_url}report/incomes?type=${type}&limit=${limit}&page=${page}&sort=${sort}${searchData}`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setDirectIncome(res?.data?.data);
            setTotalRecord(res?.total);
            setTodayIncome(res);
            setResult(res?.results);
            setTotalAmount(res?.totalAmount);
        }
        if (page > 1) {
            setKeycount(0);
            var count = page - 1;
            setKeycount(count * limit);
        }

    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };

    const refresh = () => window.location.reload();

    return (
        <main>
            <div className="uni-level-outer">
                <div className="coustom_container">
                    <div className="box-content">
                        <Row>
                            <div className="col-lg-3 col-sm-6">
                                <div className="card table-details">
                                    <div className="card-header">
                                        <div className="card-imgs">
                                            <img src={card_1} alt="icon-bg" />
                                            <PersonRounded className="icon user" />
                                        </div>
                                        <p className="text-sm mb-0 text-capitalize">
                                            Today Income
                                        </p>
                                    </div>
                                    <div className="text-start pt-1">
                                        <h4 className="active-txt"> <span className="de-sc"></span> ${Number(todayIncome?.todayAmount || 0)?.toFixed(4)} </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="card table-details">
                                    <div className="card-header">
                                        <div className="card-imgs">
                                            <img src={card_1} alt="icon-bg" />
                                            <PersonRounded className="icon user" />
                                        </div>
                                        <p className="text-sm mb-0 text-capitalize">
                                            Yesterday Income
                                        </p>
                                    </div>
                                    <div className="text-start pt-1">
                                        <h4 className="active-txt"> <span className="de-sc"></span> ${Number(todayIncome?.yesterdayAmount || 0)?.toFixed(2)} </h4>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className="level_inner loader_main">
                        {loading &&
                            <div className="spinner_bg">

                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        }
                        <div className="top_section_unilevel">
                            <div className="date">
                                <span className='from'>From</span>   <input type='date' className="form-control" placeholder='' onChange={(e: any) => setStartDate(e.target.value)} />
                            </div>

                            <div className="date">
                                <span className='to'>To</span>   <input type='date' className="form-control" placeholder='' onChange={(e: any) => setEndDate(e.target.value)} />
                            </div>
                            <div className='date'>
                                <span className='select_level'>Level</span>
                                <select className="form-control select_option" onChange={(e: any) => setLevelSearch(e.target.value)} >
                                    <option value="">All</option>

                                    {allLevels?.map((item: any, key: any) => (
                                        <option key={key} value={item}> {'Level' + " " + item} </option>
                                    ))}
                                    {/* <option value="0">Level</option>
                                    <option value="1">All</option>
                                    <option value="2">Level 1</option>
                                    <option value="3">Level 2</option>
                                    <option value="4">Level 3</option> */}
                                </select>
                            </div>

                            <p className='level_open'>{type === 'direct_business_incentive' ? "Direct Profit" : type === 'level_business_incentive' ? "Layered Investment Returns" : type === 'staking_incentive' ? "Profit on Investment" : type === 'royality_incentive' ? "Royalty Profit" : type === 'difference_business_incentive' ? "Difference Business Incentive" : ""}: <span>${totalAmount?.toFixed(4)}</span></p>

                            <div className="form-btn">

                                {/* <button className="searchicons">
                                    <SearchIcon />
                                </button> */}
                                <button className="searchicons" onClick={refresh} >
                                    <ReplayIcon />
                                </button>
                            </div>
                        </div>
                        <div className="table_section">
                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <Table className="support_table">
                                        <thead>
                                            <tr className="table_heading_row">
                                                <th className="table_heading">#</th>
                                                <th className="table_heading">Amount</th>
                                                <th className="table_heading">From</th>
                                                <th className="table_heading">Description</th>
                                                <th className="table_heading"> Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {directIncome?.map((item: any, index: any) => {
                                                return (
                                                    <>

                                                        <tr className="table_data_row">
                                                            <td className="table_data">{page > 1 ? index + keyCount + 1 : index + 1}</td>
                                                            <td className="table_data">${item?.amount?.toFixed(8)}</td>
                                                            <td className="table_data">
                                                                <div className="pic_text"> {item?.from} </div> </td>
                                                            <td className="table_data">{item?.description}</td>
                                                            <td className="table_data payment_fee">
                                                                {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalRecord / 10}
                                        previousLabel="<"
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default DirectIncome