import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";

const BusinessLevel = (props: any) => {
    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });

    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [businessLevel, setBusinessLevel] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [incomeChange, setIncomeChange] = useState('');

    useEffect(() => {
        getAllIncomeReport();
    }, [incomeChange, page, totalRecord]);


    const getAllIncomeReport = async () => {
        setLoading(true);

        let res = await _fetch(`${api_url}report/level-business-detail?limit=10&page=${page}`, "GET", {});
        if (res?.status === "success") {
            setBusinessLevel(res?.data);
            setLoading(false);
            setTotalRecord(res?.total);
        }
    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };
    return (
        <main>
            <div className="uni-level-outer">
                <div className="coustom_container">
                    <div className="level_inner loader_main">
                        {loading &&
                            <div className="spinner_bg">
                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        }
                        <div className="top_section_unilevel cash_flow">
                            <h4>Business Level</h4>
                            <div className="led-selected">
                                <form className="ledger-form">

                                    {/* <select className="form-control ledger" onChange={(e: any) => { setIncomeChange(e.target.value); setpage(1) }}>
                                        <option value="">All</option>
                                        <option value="staking_incentive">Staking Incentive</option>
                                        <option value="direct_business_incentive">Direct Business Incentive</option>
                                        <option value="level_business_incentive">Level Business Incentive</option>
                                        <option value="royality_incentive">Royality Incentive</option>
                                        <option value="permanent_reward">Permanent Reward</option>
                                        <option value="difference_business_incentive">Difference Business Incentive</option>
                                        <option value="withdraw_request">Withdraw</option>

                                    </select> */}

                                </form>
                            </div>
                        </div>
                        <div className="table_section">
                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <Table className="support_table">
                                        <thead>
                                            <tr className="table_heading_row">
                                                <th className="table_heading">Level</th>
                                                <th className="table_heading">Team</th>
                                                <th className="table_heading">Total Business</th>
                                                <th className="table_heading">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {businessLevel?.map((item: any, index: any) => {
                                                return (
                                                    <tr className="table_data_row" key={index}>
                                                        <td className="table_data">{item?.level}</td>
                                                        <td className="table_data">{item?.team}</td>
                                                        <td className="table_data payment_fee"> {item?.totalBusiness}</td>
                                                        <td className="table_data payment_fee">
                                                            <Link to='/viewbusniesslevel' state={{ users: item?.users }}>View</Link>
                                                            {/* <Link to='/viewbusniesslevel'>
                                                            View</Link> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </Table>
                                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalRecord / 10}
                                        previousLabel="<"
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default BusinessLevel;
