import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Table } from 'react-bootstrap';
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from '../../config/api';
import dateformat from "dateformat";
import { useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import ReactPaginate from "react-paginate";

const StakeHistory = (props: any) => {
    const { type } = useParams();

    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });

    const [directIncome, setDirectIncome] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [levelSearch, setLevelSearch] = useState('');
    const [searchedType, setSearchType] = useState('level');
    const [startDate, setStartDate] =useState('');
    const [endDate, setEndDate] =useState('');
    const [allLevels, setAllLevels] = useState([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
      ]);
      const [keyCount, setKeycount] = useState(0);

    useEffect(() => {
        getIncomeReport();
    }, [levelSearch, page,type]);

    const getIncomeReport = async () => {
        setLoading(true);
        if (levelSearch ) {
                 var searchData = `&level=${levelSearch}`;
          } else {
            var searchData = "";
          }

        let res = await _fetch(`${api_url}report/stake-details?type=${type}&limit=10&page=${page}${searchData}`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setDirectIncome(res?.data?.data);
            setTotalRecord(res?.total);
            setResult(res?.results);
            setTotalAmount(res?.totalAmount);
        }
        
    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };

    const refresh = () => window.location.reload();

    return (
        <main>
            <div className="uni-level-outer">
                <div className="coustom_container">
                    <div className="level_inner loader_main">
                        {loading &&
                            <div className="spinner_bg">

                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        }
                        {/* <div className="top_section_unilevel">
                            <div className="date">
                                <span className='from'>From</span>   <input type='date' className="form-control" placeholder='' onChange={(e: any) => setStartDate(e.target.value)} />
                            </div>

                            <div className="date">
                                <span className='to'>To</span>   <input type='date' className="form-control" placeholder='' onChange={(e: any) => setEndDate(e.target.value)}/>
                            </div>
                            <div className='date'>
                                <span className='select_level'>Level</span>
                                <select className="form-control select_option" onChange={(e : any) => setLevelSearch(e.target.value)} >
                                <option value="">All</option>

                                {allLevels?.map((item: any, key: any) => (
                                  <option key={key} value={item}> {'Level' + " " + item} </option>
                                ))}
                                  
                                </select>
                            </div>

                            <p className='level_open'>{type === 'direct_income' ? "Direct Profit" : type === 'level_income' ? "Layered Investment Returns" : type === 'roi_income' ? "Profit on Investment" : type === 'royalty_income' ? "Royalty Profit" :  type === 'global_lifetime_pool_income' ? "Eternal Global Pool Profit" : ""}: <span>${totalAmount?.toFixed(2)}</span></p>

                            <div className="form-btn">

                                <button className="searchicons" onClick={refresh} >
                                    <ReplayIcon />
                                </button>
                            </div>
                        </div> */}
                        <div className="table_section">
                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <Table className="support_table">
                                        <thead>
                                            <tr className="table_heading_row">
                                                <th className="table_heading">Token</th>
                                                <th className="table_heading">Dollar</th>

                                                <th className="table_heading">Stake Date </th>
                                                <th className="table_heading">Un-Stake Date </th>
                                                <th className="table_heading">{type == 'sponsor_stake' ? 'Month':'Days'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {directIncome?.map((item: any, index: any) => {
                                                return (
                                                    <>

                                                        <tr className="table_data_row">
                                                            <td className="table_data">{item?.amount?.toFixed(4)}</td>
                                                            <td className="table_data">${(item?.amount * item?.apy)?.toFixed(4)}</td>

                                                            <td className="table_data">{item?.investDate}</td>
                                                            <td className="table_data">{item?.unStakeDate} </td>
                                                            <td className="table_data">{type == 'sponsor_stake' ? item?.days: ( (item?.days) * 30)}</td>
                                                            
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalRecord / 10}
                                        previousLabel="<"
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default StakeHistory