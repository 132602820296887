import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import drag_file from '../../Assets/Image/darg_file.png';
import ques from '../../Assets/Image/ques.png';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useNavigate } from 'react-router-dom';

const ExpoArchivers = (props: any) => {

    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });
    const navigate = useNavigate();

    const [address, setAddress] = useState('');
    const [image, setImage] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [uploadFrontImage, setUploadFrontImage] = useState('');
    const [uploadBackImage, setUploadBackImage] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [phoneno, setPhoneNo] = useState('');
    const [qualification, setQualification] = useState('');

    const handleFrontImage = (e: any) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result as string;;
                setUploadFrontImage(base64Image);
            };
            reader.readAsDataURL(selectedImage);
        }
    };

    const handleBackImage = (e: any) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result as string;;
                setUploadBackImage(base64Image);
            };

            reader.readAsDataURL(selectedImage);
        }
    };
    const archieverName = localStorage.getItem('archieverusername');

    const SubmitArchiever = async () => {

        let data = {
            'username': archieverName,
            'name' : nameValue,
            'phoneNumber' : phoneno,
            'address' : address,
            'qualification' : qualification,
            'addharfrontimage' : uploadFrontImage,
            'addharbackimage' : uploadBackImage,
        }
    
        let res = await _fetch(`${api_url}sponsor/create-archiever`, "POST", data);
    
        if (res?.status === "success") {
            toasted.success(res?.message);
            navigate('/archiever-history');
        } else {
            toasted.error(res?.message);  
        }
    };
    

    return (
        <main>
            <div className="support_main">
                <div className="coustom_container">
                    <div className="support_inner">
                        <div className="top_head_sec">
                            <h4>CREATE ARCHIEVER</h4>
                        </div>
                        <div className="support-section">

                            <div className="desc p-2">
                                <input type='text' className='text_sec' onChange={(e: any) => setNameValue(e.target.value)} name='namevalue' value={nameValue} placeholder='Name' />
                            </div>

                            <div className="desc p-2">
                                <input type='text' className='text_sec' onChange={(e: any) => setPhoneNo(e.target.value)} value={phoneno} name='phno' placeholder='Phone Number' />
                            </div>
                            <div className="desc p-2">
                                <textarea rows={4} placeholder='Address' className='text_sec' onChange={(e: any) => setAddress(e.target.value)} name='address' value={address} />
                            </div>
                            <div className="desc p-2">
                                <input type='text' className='text_sec' name='education' placeholder='Qualification' onChange={(e: any) => setQualification(e.target.value)} value={qualification} />
                            </div>
                        </div>
                        <div className="row support_row">
                            <div className="col-lg-7 col-md-6 col-sm-6">
                            </div>
                            <div className="desc py-2 px-4">
                                <label className='mb-1'>Addhar Front Image</label><br/>
                                <input type="file" placeholder='Addhar Front Image' className='text_sec type-choose' id="myfile" name="aadhaarFront" onChange={handleFrontImage} />
                            </div>
                            <div className="desc py-2 px-4">
                                <label  className='mb-1'>Addhar Back Image</label><br/>
                                <input type="file" className='text_sec type-choose' id="myfile" name="aadhaarBack" onChange={handleBackImage} />
                            </div>
                        </div>
                        <div className="send_sec">
                            <button className='button_style send' onClick={SubmitArchiever}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ExpoArchivers