import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import { useLocation } from 'react-router-dom';

const ViewBusinessLevel = (props: any) => {
    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });
    const location = useLocation();
    const { users } = location.state || {};

    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [businessLevel, setBusinessLevel] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [incomeChange, setIncomeChange] = useState('');

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };

    return (
        <main>
            <div className="uni-level-outer">
                <div className="coustom_container">
                    <div className="level_inner loader_main">
                        {/* {loading &&
                            <div className="spinner_bg">
                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        } */}
                        <div className="top_section_unilevel cash_flow">
                            <h4>Business Level View</h4>
                            <div className="led-selected">
                                <form className="ledger-form">
                                </form>
                            </div>
                        </div>
                        <div className="table_section">
                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <Table className="support_table">
                                        <thead>
                                            <tr className="table_heading_row">
                                                <th className="table_heading">#</th>
                                                <th className="table_heading">Username</th>
                                                <th className="table_heading">Rank</th>
                                                <th className="table_heading">Total Business</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users?.map((item: any, index: any) => {
                                                return (
                                                    <tr className="table_data_row" key={index}>
                                                        <td className="table_data">{index + 1}</td>
                                                        <td className="table_data">{item?.username}</td>
                                                        <td className="table_data">{item?.rank}</td>
                                                        <td className="table_data payment_fee"> {item?.direct_business}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </Table>
                                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalRecord / 10}
                                        previousLabel="<"
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ViewBusinessLevel;
