import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";

const Ledger = (props: any) => {
  useEffect(() => {
    document.title = `${props.pageTitle}`;
  });

  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [allIncomes, setAllIncomes] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [incomeChange, setIncomeChange] = useState('');

  useEffect(() => {
    getAllIncomeReport();
  }, [incomeChange, page, totalRecord]);


  const getAllIncomeReport = async () => {
    setLoading(true);
    if (incomeChange) {
      let res = await _fetch(`${api_url}report/all-incomes?type=${incomeChange}&limit=10&page=${page}&sort=created_at`, "GET", {})
      if (res?.status === "success") {
        setLoading(false);
        setAllIncomes(res?.data?.data);
        setTotalRecord(res?.total);
      }
    }
    else {
      let res = await _fetch(`${api_url}report/all-incomes?limit=10&page=${page}&sort=created_at`, "GET", {})

      if (res?.status === "success") {
        setLoading(false);
        setAllIncomes(res?.data?.data);
        setTotalRecord(res?.total);
      }
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };
  return (
    <main>
      <div className="uni-level-outer">
        <div className="coustom_container">
          <div className="level_inner loader_main">
            {loading &&
              <div className="spinner_bg">
                <div className="color_ring">  <ColorRing
                  visible={true}
                  height="80"
                  width="80"

                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                /></div>

              </div>
            }
            <div className="top_section_unilevel cash_flow">
              <h4>LEDGER</h4>
              <div className="led-selected">
                <form className="ledger-form">

                  <select className="form-control ledger" onChange={(e: any) => {setIncomeChange(e.target.value);setpage(1)}}>
                    <option value="">All</option>
                    <option value="staking_incentive">Staking Incentive</option>
                    <option value="direct_business_incentive">Direct Business Incentive</option>
                    <option value="level_business_incentive">Level Business Incentive</option>
                    <option value="royality_incentive">Royality Incentive</option>
                    <option value="permanent_reward">Permanent Reward</option>
                    <option value="difference_business_incentive">Difference Business Incentive</option>
                    <option value="withdraw_request">Withdraw</option>

                  </select>

                </form>
              </div>
            </div>
            <div className="table_section">
              <div className="scrolling_table">
                <div className="scroll_under">
                  <Table className="support_table">
                    <thead>
                      <tr className="table_heading_row">
                        <th className="table_heading">Date</th>
                        <th className="table_heading">Amount</th>
                        <th className="table_heading">Category</th>
                        <th className="table_heading">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allIncomes?.map((item: any, index: any) => {
                        return (
                          <>

                            <tr className="table_data_row">
                              <td className="table_data">
                                <div className="pic_text">{dateFormat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</div>
                              </td>
                              <td className="table_data">${item?.amount?.toFixed(8)}</td>
                              <td className="table_data payment_fee"> {item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</td>
                              <td className="table_data">
                                {item?.description}
                              </td>
                            </tr>
                          </>
                        )
                      })}

                    </tbody>
                  </Table>
                  {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecord / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Ledger;
