import React, { useEffect, useState } from 'react'
import { api_url, logo } from '../config/Config'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountBalanceWallet, AutoStories, CardGiftcard, CloseSharp, Dashboard, Description, Groups, Help, Key, Logout, MonetizationOn, Payment, PersonOutline, ReceiptLong, ShoppingBasket, ViewCompact } from '@mui/icons-material';
import _fetch from '../config/api';
import toasted from '../config/toast';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

const Sidebar = (props:any) => {
  let location = useLocation();
  const [showSubmenu, setShowSubmenu] = useState(false);

  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };

  const navigate = useNavigate();

  const logOut = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }



  const ToggleButtonRemover = () => {
    const body = document.body;
    body.classList.remove("show-index-bar");
  }

  return (
    <>

      <div className="app-sidebar">
        <div className='sidbar-overly' onClick={ToggleButtonRemover}></div>


        <div className="app-sidebar3">
          <div className="sidebar">
            <div className="app-sidebar__logo">
              <a className="header-brand" href="/dashboard">
                <div className="mode">

                  <img src={logo} className="header-brand-img light-logo" alt="logo" />

                </div>

              </a>

              <button type='button' className='close-btns' onClick={ToggleButtonRemover}>
                <CloseSharp />
              </button>
            </div>


            <ul className="sidebar_menu">

              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className='only_button'>
                    <Link to="/dashboard" onClick={ToggleButtonRemover} className={location.pathname === '/dashboard' ? "drop_link active_Link" : ' drop_ink'}><Dashboard />Dashboard</Link>
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header className='only_button'>
                    <Link to="/profile" onClick={ToggleButtonRemover} className={location.pathname === '/profile' ? "drop_link active_Link" : ' drop_ink'}><PersonOutline />Profile</Link>
                  </Accordion.Header>
                </Accordion.Item>
                {/* 
                <Accordion.Item eventKey="2">
                  <Accordion.Header className='only_button'>
                    <Link to="/deposit" onClick={ToggleButtonRemover} className={location.pathname === '/deposit' ? "drop_link active_Link" : ' drop_ink'}><MonetizationOn />Deposit</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                {/* <Accordion.Item eventKey="3">
                  <Accordion.Header><span><ShoppingBasket />Infrastructure</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><a href='/buy-infra'> Buy Infra</a></li>
                      <li><a href="/trading-profit">Buy History</a></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}


                <Accordion.Item eventKey="4">
                  <Accordion.Header className='only_button'>
                    <Link to={"/team"} onClick={ToggleButtonRemover} className={location.pathname === '/team' ? "drop_link active_Link" : ' drop_ink'}><Groups />Team</Link>
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header className='only_button'>
                    <Link to="/business" onClick={ToggleButtonRemover} className={location.pathname === '/business' ? "drop_link active_Link" : ' drop_ink'}><ReceiptLong />My Business Report</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className='only_button'>
                    <Link to="/archiever-history" onClick={ToggleButtonRemover} className={location.pathname === '/archiever-history' ? "drop_link active_Link" : ' drop_ink'}><ReceiptLong />Archiever Report</Link>
                  </Accordion.Header>
                </Accordion.Item> */}


                {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className='only_button'>
                    <Link to="/almaterybusiness" onClick={ToggleButtonRemover} className={location.pathname === '/almaterybusiness' ? "drop_link active_Link" : ' drop_ink'}><ReceiptLong />Almatry Business Report</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className='only_button'>
                    <Link to="/overview" onClick={ToggleButtonRemover} className={location.pathname === '/overview' ? "drop_link active_Link" : ' drop_ink'}><ViewCompact />Overview</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="6">
                  <Accordion.Header><span><AccountBalanceWallet />Income</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/report/staking_incentive'>Staking Incentive</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/direct_business_incentive'>Direct Business Incentive</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/level_business_incentive'>Level Business Incentive</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/royality_incentive'>Royality Incentive</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/permanent_reward'>Permanent Reward</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/difference_business_incentive'>Difference Business Incentive</Link></li>



                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header><span><DataThresholdingIcon />Stake Vista</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to="/stake-report/self_stake">Self Stake </Link></li>
                      <li><Link onClick={ToggleButtonRemover} to="/stake-report/sponsor_stake">Direct Stake</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey="7">
                  <Accordion.Header className='only_button'>
                    <Link to="/ledger" onClick={ToggleButtonRemover} className={location.pathname === '/ledger' ? "drop_link active_Link" : ' drop_ink'}><Description />Ledger</Link>
                  </Accordion.Header>
                </Accordion.Item>



                {/* <Accordion.Item eventKey="9">
                  <Accordion.Header className='only_button'>
                    <Link to="/" className={location.pathname === '/Cryptic' ? "drop_link active_Link" : ' drop_ink'}><Key />Cryptic ID</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="10">
                  <Accordion.Header><span><Payment />Withdraw</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/payout-request'>Request</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/pay-history'>History</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="11">
                  <Accordion.Header><span><Payment />Expo Archiever</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/create-archiever'>Archiever Request</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/archiever-history'>History</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}


                {/* <Accordion.Item eventKey="12">
                  <Accordion.Header className='only_button'>
                    <Link to="/support" className={location.pathname === '/support' ? "drop_link active_Link" : ' drop_ink'}><Help />Support</Link>
                  </Accordion.Header>
                </Accordion.Item> */}
                <Accordion.Item eventKey="12">
                  <Accordion.Header><span><Help />Support</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>

                      <li><Link onClick={ToggleButtonRemover} to='/create-ticket'>Create Ticket</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/ticket-history'>Ticket History</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                  <Accordion.Header className='only_button'>
                    <Link to="/" className={location.pathname === '/logout' ? "drop_link active_Link" : ' drop_ink'} onClick={logOut}><Logout />Log out</Link>
                  </Accordion.Header>
                </Accordion.Item>

              </Accordion>


            </ul>
          </div>


        </div>
      </div>

    </>
  )
}

export default Sidebar